// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('jquery')
require('./../src/c12-search-filter')

import './../src/fomantic-ui-2.8.4'
import './../styles/tailwind'
import './../styles/fomantic-ui'
import './../styles/psychnet-icons/psychnet-icons'
import './../styles/application'

const serialize = require('form-serialize')
const debounce = require('debounce')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)


// Damit wir Event-Handler einfach auf mehrere Events binden können, zB `on("page:ready form:reload")`.
$(function() {
  $(document).trigger("page:ready")
})

$(document).on('change', "#geo_state", function() {
  const region = $(this).val();
  const districts = DISTRICTS[region];
  const $districtsSelect = $("#geo_region");

  const currentVal = $districtsSelect.val();

  $districtsSelect.empty();

  if (districts) {
    districts.forEach(function(d) {
      $districtsSelect.append(`<option value='${d}'>${d}</option>`)
    });

    $districtsSelect.prop('disabled', false).parent().removeClass('disabled').parent().removeClass('disabled')

    $districtsSelect.dropdown('refresh');

    if (districts.includes(currentVal)) {
      $districtsSelect.dropdown('set selected', currentVal);
    } else {
      $districtsSelect.dropdown('set exactly', null);
    }
  } else {
    $districtsSelect.dropdown('set exactly', null);
    $districtsSelect.prop('disabled', true).parent().addClass('disabled').parent().addClass('disabled')
    $districtsSelect.dropdown('refresh');
  }
});

$(document).on('change', "input[name='geo_search_type']", function() {
  $('.geo-part').hide()
  $(`.geo-part-${$("input[name='geo_search_type']:checked").val()}`).show()
})

$(document).on('change', "[name='order']", function() {
  var form = $(this).closest("form")

  // Es gibt das Dropdown doppelt (für Mobile bzw. Desktop) - das jeweils nicht aktive darf aber die gewählte Option nicht überschreiben.
  form.find("[name='order']").not(this).remove()

  form.submit()
})

$(document).on('page:ready form:reload', () => {

  const input = document.querySelector('#geo_address')
  if (input) {
    const options = {
      componentRestrictions: {country: 'at'}
    }
    new google.maps.places.Autocomplete(input, options)
  }

  // TODO: Auf Mobile öffnen sich die Dropdowns bei Touchstart, was blöd ist, weil es das Scrollen verhindert. Theoretisch könnten wir `touchend` als Trigger verwenden, aber:
  //  * `touchend` alleine geht dann am Desktop nicht.
  //  * `touchend click` macht die Interaktion mit Dropdowns auf Mobile kaputt (sie öffnen sich beim Schließen sofort wieder).
  //  * Fallunterscheidung geht nicht: Es gibt keine Möglichkeit initial sauber rauszufinden, ob die Seite via Touch bedient wird oder nicht (was ja unabhängig davon ist, ob der Browser Touch-Events unterstützt – Chrome unterstützt die zB immer, auch am Desktop).
  //
  // Ein möglicher Workaround: Warten bis zum ersten Mal _irgendein_ Touch-Event auftritt, und dann alles für Touch rekonfigurieren (keine Ahnung ob das mit den Fomantic-Dropdowns gscheit gehen würde).
  const dropdownTrigger = 'click';
  $(
    '#age, #setting, #language, #geo_state, #geo_region, #insurance, #financing'
  ).dropdown({
    clearable: true,
    on: dropdownTrigger,
  })
  $('#order, #order_mobile').dropdown({on: dropdownTrigger})

  const initTopicsDropdown = function() {
    $('#topics').dropdown({
      on: dropdownTrigger,
      fullTextSearch: 'exact',
      duration: 20, // Wir brauchen ein minimales Delay, sonst wird auf Android das Onscreen-Keyboard sofort wieder ausgeblendet, wenn man nicht direkt ins Input-Feld geklickt hat.
      label: {
        transition: "scale",
        duration: 20, // Siehe oben.
        variation: false,
      },
    })
  }

  // Workaround für folgenden Bug: Wenn nur ein einzelnes Topic selektiert ist, dann wird das beim (Neu-)Laden der Seite nicht als Label angezeigt. Grund dürfte sein, dass FomanticUI versucht, dieses Label mit einer Transition einzublenden (und das macht es nur dann, wenn es nur genau eine selektierte Option gibt). Der JS-Code von FomanticUI überprüft dabei, ob die angegebene Transition als CSS-Klasse existiert, und bricht sonst mit einem Fehler ab. Offenbar kommts beim Laden der Seite dann zu einer Race-Condition, sodass FomanticUI glaubt die Transition existiert nicht. Timeout scheint zu helfen (und stört nicht wirklich bei der Interaktion).
  setTimeout(initTopicsDropdown, 200)

  $('.ui.checkbox').checkbox()

  $("input[name='geo_search_type'], #geo_state").trigger('change')
})

window.addEventListener('pageshow', function(event) {
  if (event.persisted) {
    $('#topics').dropdown()
    $('.c06__submit-button').removeClass('has-spinner')
  }
})

$(document).on("click", "[data-show-modal]", function(ev) {
  const selector = $(this).attr("data-show-modal")
  ev.preventDefault()
  $(selector).modal("show")
})

$(document).on("click", "[data-scroll-to-top]", function(ev) {
  ev.preventDefault()
  $("html, body").animate({scrollTop: 0}, 150, "swing")
})

$(document).on("click", "[data-disable-with-class]", function(ev) {
  $(this).addClass($(this).data("disable-with-class"))
})

$(document).on("click", "[data-profile-backlink]", function(ev) {
  if (location.hash == "#!from-search" && history.length > 1) {
    ev.preventDefault()
    history.back()
  }
})

const handleReloadOnChange = (ev) => {
  const selector = `#${$(ev.target).data('reload-on-change')}`
  const form = $(selector)
  const spinner = $("#spinner-overlay > :first-child").clone()
  $(ev.target).closest("[data-spinner-overlay-on-reload").append(spinner)

  $.ajax({
    url: form.attr("action"),
    data: form.serialize(),
    success: function(data) {
      const updatedContent = $(data).find(selector)
      form.replaceWith(updatedContent)
      $(document).trigger("form:reload")
    }
  });
}

$(document).on("change", "[data-reload-on-change]", debounce(handleReloadOnChange, 100))

// VH-Fix für Mobile Safari und Co, siehe https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const setVhProperty = function() {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}
setVhProperty()
window.addEventListener('resize', setVhProperty)
